import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className }) => (
    <svg className={className} viewBox="0 0 66 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.2701 18.7621H8.94092C7.74283 18.7621 7.07532 17.3777 7.82146 16.4403L15.9406 6.23998C16.7428 5.23216 17.9607 4.64499 19.2488 4.64501L22.632 4.64505C23.3025 4.64506 23.883 5.11057 24.0286 5.76497L24.3983 7.42562C24.6978 8.77143 26.6835 8.5315 26.6539 7.15308L26.6314 6.10421C26.6142 5.30305 27.2583 4.64397 28.0596 4.6427L30.6009 4.63867C31.3919 4.63742 32.0339 5.27837 32.0339 6.06947L32.0339 17.3331C32.0339 18.1226 31.3944 18.7629 30.6048 18.7639L25.7203 18.7696C25.1219 18.7703 24.5863 18.3985 24.3776 17.8376L22.5053 12.8044C22.1149 11.7547 20.7242 11.5403 20.0358 12.4237L15.6041 18.1105C15.2836 18.5217 14.7914 18.7621 14.2701 18.7621Z"
            fill="#F56960"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.7351 17.3449V6.07288C33.7351 5.28268 34.3745 4.64209 35.1647 4.64209L45.502 4.6421C48.8461 4.6421 51.4555 6.10386 51.6664 10.3455C51.6896 10.8106 52.3899 11.2029 53.2875 11.7056C54.9379 12.6299 57.2553 13.9278 57.2553 16.739C57.2553 18.7757 53.6904 18.7757 53.6904 18.7757H35.1659C34.3757 18.7757 33.7351 18.1351 33.7351 17.3449ZM39.7086 9.1327V8.29696C39.7086 8.29696 43.8623 7.95291 43.8623 8.69024C43.8623 9.42757 39.7086 9.1327 39.7086 9.1327ZM39.7086 14.1093V14.945C39.7086 14.945 45.6565 15.3015 45.6565 14.5029C45.6565 13.7042 39.7086 14.1093 39.7086 14.1093Z"
            fill="#F56960"
        />
        <path
            d="M36.9921 31.7837L34.2086 23.1292C33.7907 21.8301 31.9636 21.7998 31.5029 23.0844L28.347 31.8841C27.9738 32.9244 26.6116 33.1656 25.904 32.3166L17.9108 22.7269C17.6393 22.4012 17.2371 22.2128 16.8131 22.2128H6.63837C5.42997 22.2128 4.76699 23.6194 5.53606 24.5515L28.837 52.7908C29.6278 53.7492 31.1805 53.3115 31.3544 52.0812L31.6522 49.9741C31.8581 48.5174 33.9631 48.5205 34.1647 49.9778L34.454 52.0694C34.6245 53.3018 36.1791 53.7429 36.9714 52.7837L60.2918 24.5521C61.0616 23.6202 60.3987 22.2128 59.1899 22.2128H48.6097C48.1891 22.2128 47.7899 22.3981 47.5184 22.7192L39.4439 32.2688C38.7195 33.1255 37.3357 32.8517 36.9921 31.7837Z"
            fill="#F56960"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2529 2.53688C17.3191 2.53688 15.4909 3.4192 14.2877 4.93318L3.91387 17.9868C2.05219 20.3294 2.0811 23.6545 3.98325 25.9644L28.0194 55.1526C30.5563 58.2333 35.2741 58.2333 37.8111 55.1526L61.8472 25.9644C63.7494 23.6545 63.7783 20.3294 61.9166 17.9868L51.5428 4.93318C50.3396 3.4192 48.5114 2.53688 46.5776 2.53688H19.2529ZM12.3016 3.35482C13.9861 1.23524 16.5455 0 19.2529 0H46.5776C49.285 0 51.8444 1.23524 53.5289 3.35482L63.9027 16.4084C66.5091 19.6881 66.4686 24.3432 63.8056 27.577L39.7694 56.7652C36.2177 61.0782 29.6128 61.0782 26.0611 56.7652L2.02491 27.577C-0.638091 24.3432 -0.678576 19.6881 1.92778 16.4084L12.3016 3.35482Z"
            fill="#F56960"
        />
    </svg>
);

Logo.propTypes = {
    className: PropTypes.string,
};

export default Logo;
