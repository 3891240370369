import React from 'react';
import PropTypes from 'prop-types';

const GiveSupportIcon = ({ className }) => (
    <svg className={className} viewBox="0 0 451 316" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_96_1362)">
            <rect x="0.176453" width="450.647" height="316" rx="10.8821" fill="white" />
            <circle
                cx="225.5"
                cy="158"
                r="163.167"
                stroke="#F56960"
                strokeOpacity="0.1"
                strokeWidth="1.31259"
            />
            <circle
                cx="225.5"
                cy="158"
                r="138.509"
                stroke="#F56960"
                strokeOpacity="0.25"
                strokeWidth="1.31259"
            />
            <circle
                cx="225.5"
                cy="158"
                r="114.897"
                stroke="#F56960"
                strokeOpacity="0.5"
                strokeWidth="1.31259"
            />
            <circle cx="225.5" cy="158" r="92.5769" stroke="#F56960" strokeWidth="1.31259" />
            <circle cx="225.5" cy="158" r="63.6514" stroke="#002071" strokeWidth="1.31259" />
            <path
                d="M293.511 248.592C312.918 269.877 338.042 313.616 352.907 336.319C355.174 339.782 358.434 342.438 362.231 344.087L416.852 367.818C439.819 377.796 462.745 344.262 445.475 326.129C399.703 278.067 351.944 226.34 334.538 204.568C332.63 202.182 331.321 199.388 330.631 196.412C327.729 183.901 326.117 174.889 324.875 160.72C324.561 157.144 323.413 153.686 321.592 150.592C312.207 134.646 309.273 123.812 311.054 107.007L311.068 106.875C311.373 104.004 311.72 100.732 309.706 98.6812C309.534 98.5064 309.349 98.3424 309.147 98.1888C304.712 94.8107 298.151 98.1259 294.87 102.633C292.253 106.228 290.606 110.284 289.251 116.176C288.596 119.023 288.66 121.995 289.349 124.834C291.251 132.679 293.146 137.983 294.189 141.72C295.854 147.683 288.897 149.571 283.556 146.441C276.531 142.325 273.911 137.4 266.675 131.479C265.744 130.717 264.843 129.91 264.021 129.032L222.426 84.5514C218.39 80.2344 212.057 76.5686 207.382 80.1849C207.269 80.2726 207.158 80.3626 207.049 80.4548C202.868 84.0076 205.648 90.3287 209.193 94.5168L247.97 140.33C248.446 140.892 248.654 141.633 248.542 142.361C248.246 144.27 246.012 145.164 244.481 143.986L187.563 100.189C184.408 97.7609 180.262 95.549 177.276 98.182C172.019 102.818 176.423 111.52 181.898 115.897L231.28 155.369C231.937 155.894 232.263 156.731 232.135 157.562C231.872 159.257 229.94 160.108 228.513 159.157L180.99 127.513C176.978 124.842 171.655 122.674 167.827 125.603C167.258 126.039 166.76 126.54 166.337 127.105C162.795 131.835 167.47 137.942 172.221 141.454L221.674 178.003C221.946 178.204 222.16 178.473 222.293 178.783C223.031 180.498 221.123 182.144 219.536 181.162L185.602 160.179C183.712 159.01 181.53 158.021 179.368 158.533C178.732 158.683 178.2 158.916 177.755 159.227C172.116 163.167 179.016 171.849 184.657 175.787L216.654 198.128C218.072 199.119 219.376 200.284 220.536 201.568C243.556 227.05 257.825 235.117 284.231 242.998C287.76 244.051 291.031 245.871 293.511 248.592Z"
                fill="#F7F7F7"
                stroke="#4A4A4A"
                strokeWidth="1.31259"
                strokeLinecap="round"
            />
            <path
                d="M289.004 173.107C277.862 178.725 282.546 207.852 297.179 216.65"
                stroke="#9B9A9A"
                strokeWidth="1.31259"
                strokeLinecap="round"
            />
            <path
                d="M164.229 234.893C143.909 258.179 97.4123 328.041 78.0478 357.169C74.2407 362.895 68.0139 366.475 61.1527 366.937L24.5491 369.403C3.78614 370.802 -8.29055 344.419 5.93303 329.228C48.6369 283.622 105.113 220.319 125.198 194.17C127.059 191.747 128.314 188.95 128.958 185.963C131.439 174.445 132.806 166.051 133.76 153.058C134.023 149.478 135.119 146.004 136.886 142.879C145.28 128.037 147.847 117.9 146.007 102.41C145.666 99.5391 145.027 97.0225 146.796 94.7352C150.174 90.3661 158.121 93.7449 161.36 98.2185C163.667 101.405 165.187 105.034 166.476 110.188C167.185 113.023 167.163 116 166.513 118.849C164.953 125.68 163.375 130.447 162.435 133.883C160.801 139.854 167.596 141.738 172.827 138.426C178.94 134.555 181.38 130.024 187.833 124.556C188.751 123.779 189.64 122.959 190.448 122.069L228.587 80.0279C232.4 75.8254 239.056 72.2517 243.308 76.0083C247.256 79.4958 244.492 85.1033 241.15 89.175L205.577 132.514C205.139 133.048 204.954 133.745 205.07 134.426C205.374 136.211 207.481 137.017 208.899 135.891L261.626 94.0355C264.548 91.7152 268.403 89.5834 271.239 92.0089C276.232 96.2793 272.227 104.498 267.158 108.678L221.433 146.373C220.824 146.875 220.531 147.664 220.663 148.441C220.933 150.026 222.756 150.796 224.08 149.885L267.791 119.816C271.762 117.085 277.162 114.759 280.848 117.865C281.091 118.069 281.318 118.288 281.53 118.52C285.511 122.887 280.828 129.262 276.13 132.845L230.757 167.451C230.505 167.643 230.308 167.899 230.188 168.192C229.52 169.809 231.332 171.325 232.806 170.382L264.314 150.234C266.069 149.112 268.099 148.154 270.133 148.603C270.731 148.735 271.233 148.946 271.654 149.231C276.996 152.844 270.652 161.079 265.42 164.85L235.88 186.148C234.476 187.16 233.192 188.343 232.051 189.644C210.994 213.648 197.762 221.437 173.38 229.15C169.869 230.26 166.65 232.119 164.229 234.893Z"
                fill="#D5D5D5"
                stroke="#4A4A4A"
                strokeWidth="1.31259"
                strokeLinecap="round"
            />
        </g>
        <rect
            x="2.73068"
            y="2.55423"
            width="445.539"
            height="310.892"
            rx="8.32784"
            stroke="#E4E4E4"
            strokeWidth="5.10845"
        />
        <defs>
            <clipPath id="clip0_96_1362">
                <rect x="0.176453" width="450.647" height="316" rx="10.8821" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

GiveSupportIcon.propTypes = {
    className: PropTypes.string,
};

export default GiveSupportIcon;
